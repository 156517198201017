<template>
    <main class="timing">
        <router-link class="timing__link timing__link_back link link_red"
            v-if="isReportTotalLinkVisible"
            :to="{ name: 'TimingTotal'}"
        >
            Вернуться к общим данным тайминга
        </router-link>
        <form class="timing__settings" @submit.prevent>
            <h2 class="sr-only">Настройки отчета "Тайминг"</h2>
            <daterange-picker class="timing__daterange"
                v-model="daterangeModel"
                name="report-daterange"
            >
                Период отчета
            </daterange-picker>
            <fieldset class="timing__fieldset timing__fieldset_selects">
                <select class="timing__select timing__select_zone" v-model="zoneModel">
                    <option class="timing__option" v-for="zone in zones" :key="zone.id" :value="zone.id">
                        {{zone.title}}
                    </option>
                </select>
                <select class="timing__select timing__select_mode" v-model="modeModel">
                    <option class="timing__option" v-for="mode in modes" :key="mode.id" :value="mode.id">
                        {{mode.title}}
                    </option>
                </select>
                <select class="timing__select timing__select_lateDistribution"
                    v-if="modeId === 'lateDistribution' && showLateDistributionSelect"
                    v-model="lateDistributionItemModel"
                >
                    <option class="timing__option"
                        value="total"
                    >
                        Итого
                    </option>
                    <option class="timing__option"
                        v-for="restaurant in restaurants"
                        :key="restaurant.id"
                        :value="restaurant.id"
                    >
                        {{restaurant.title}}
                    </option>
                </select>
            </fieldset>
        </form>
        <spinner class="timing__spinner" v-if="status === 'loading'" />
        <error-message class="timing__error-message" v-else-if="status === 'error'">
            Не удалось загрузить тайминг.
        </error-message>
        <router-view
            class="timing__data"
            :status="status"
            :modeId="modeId"
            :isReportTotalLinkVisible="isReportTotalLinkVisible"
            :showLateDistributionSelect="showLateDistributionSelect"
            :lateDistributionItemId="lateDistributionItemId"
            :getHoursChartOptions="getHoursChartOptions"
            :reportTiming="reportTiming"
            :standard="standard"
            :total="total"
            :userRestaurants="userRestaurants"
        />
    </main>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import { daterangeKey } from "@/helpers/daterange";
    import DaterangePicker from "@/components/DaterangePicker";

    export default {
        name: "Timing",
        components: {
            DaterangePicker
        },
        data() {
            return {
                status: "loading"
            }
        },
        computed: {
            ...mapState({
                daterange: state => state.report.daterange,
                data: state => state.timing.data,
                modeId: state => state.timing.modeId,
                zoneId: state => state.timing.zoneId,
                lateDistributionItemId: state => state.timing.lateDistributionItemId,
                showLateDistributionSelect: state => state.timing.showLateDistributionSelect,
                isReportTotalLinkVisible: state => state.ui.isReportTotalLinkVisible,
            }),
            ...mapGetters([
                "userRestaurants"
            ]),
            daterangeModel: {
                get() {
                    return this.daterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            modes: () => [
                { id: "averageTime", title: "Среднее время" },
                { id: "lateData", title: "Опоздания" },
                { id: "lateDistribution", title: "Распределение опозданий по часам" },
                { id: "deliveryTimeDistribution", title: "Распределение времени доставки" },
            ],
            zones: () => [
                { id: "all", title: "Все зоны доставки" },
                { id: "45", title: "45 минут" },
                { id: "60", title: "60 минут" },
                { id: "90", title: "90 минут" },
            ],
            modeModel: {
                get() {
                    return this.modeId;
                },
                set(value) {
                    this.setTimingParameter({ parameter: "modeId", value });
                }
            },
            zoneModel: {
                get() {
                    return this.zoneId;
                },
                set(value) {
                    this.setTimingParameter({ parameter: "zoneId", value });
                }
            },
            lateDistributionItemModel: {
                get() {
                    return this.lateDistributionItemId;
                },
                set(value) {
                    this.setTimingParameter({ parameter: "lateDistributionItemId", value });
                }
            },
            reportTiming() {
                return this.data?.[`${daterangeKey(this.daterange)}-${this.zoneId}`];
            },
            restaurants() {
                return this.reportTiming?.restaurants;
            },
            standard() {
                return this.reportTiming?.standard;
            },
            total() {
                return this.reportTiming?.total;
            },
        },
        methods: {
            ...mapMutations([
                "setReportDaterange",
                "setTimingParameter",
            ]),
            ...mapActions([
                "requestTiming"
            ]),
            showReport() {
                this.status = "success";
            },
            loadTiming() {
                this.status = "loading";

                this.requestTiming({
                    daterange: this.daterange,
                    zone: this.zoneId,
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
            getHoursChartOptions(hours) {
                let options = {
                    chart: {
                        type: "column"
                    },
                    title: {
                        text: null
                    },
                    colors: ["#e0121a", "#ff5261"],
                    xAxis: {
                        categories: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2],
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: null
                        }
                    },
                    tooltip: {
                        headerFormat: "<span style='font-size:10px'>Час: {point.key}</span><table>",
                        pointFormat: "<tr><td style='color:{series.color};padding:0'>{series.name}: </td>" +
                            "<td style='padding:0'><b>{point.y}</b></td></tr>",
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true
                            },
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: "ПН-ЧТ",
                        data: []
                    }, {
                        name: "ПТ-ВС",
                        data: []
                    }]
                };

                let workdays = hours.filter(({ day_range }) => day_range === "ПН-ЧТ");
                let holydays = hours.filter(({ day_range }) => day_range === "ПТ-ВС");

                for (let hour of options.xAxis.categories) {
                    options.series[0].data.push(Number(workdays.find(day => Number(day.hour) === hour)?.total_late) || null);
                    options.series[1].data.push(Number(holydays.find(day => Number(day.hour) === hour)?.total_late) || null);
                }

                return options;
            }
        },
        created() {
            if (this.reportTiming) {
                this.showReport();
            } else {
                this.loadTiming();
            }
        },
        watch: {
            reportTiming(reportTiming) {
                if (!reportTiming) {
                    this.loadTiming();
                }
            }
        }
    }
</script>

<style lang="scss">
    .timing__link {
        &_back {
            display: inline-block;
            margin-bottom: 10px;

            @include desktop {
                margin-bottom: 15px;
            }
        }
    }
    .timing__daterange {
        margin-bottom: 10px;

        @include desktop {
            margin-bottom: 15px;
        }
    }
    .timing__radio-group {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;

        @include desktop {
            flex-direction: row;
            margin-bottom: 15px;
        }
    }
    .timing__radio {
        & + & {
            margin-top: 5px;

            @include desktop {
                margin: 0 0 0 20px;
            }
        }
    }
    .timing__legend {
        margin-bottom: 5px;

        @include desktop {
            margin-bottom: 10px;
        }
    }
    .timing__figcaption {
        margin: 0 0 15px 10px;
    }
    .timing__select {
        min-width: 240px;
        padding: 4px 8px;
        margin-bottom: 15px;
        border-color: $gray-line;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }

    .timing__fieldset {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }
    }
</style>
